// extracted by mini-css-extract-plugin
export var analysis = "index-module--analysis---S8SD";
export var banner = "index-module--banner--Gi+gY";
export var bannerBtnsClass = "index-module--bannerBtnsClass--o8Mtp";
export var bannerDesc = "index-module--bannerDesc--XX3bX";
export var bannerSubTitleClassName = "index-module--bannerSubTitleClassName--9rwqh";
export var bannerTitle = "index-module--bannerTitle--K2lgI";
export var bannerWrapper = "index-module--bannerWrapper--w1-nj";
export var container = "index-module--container--MxPEq";
export var logo_wall_en = "index-module--logo_wall_en--igQa4";
export var section1 = "index-module--section1--+-XAr";
export var sectionOneTitle = "index-module--sectionOneTitle--YmJON";
export var section_05 = "index-module--section_05--LJ4Lp";
export var section_07 = "index-module--section_07--q+Mf6";
export var videoBtnWra = "index-module--videoBtnWra--nt8UF";