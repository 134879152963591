import React from 'react'
import classnames from 'classnames'
import Button from 'components/Base/Button'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import * as styles from './index.module.less'

/**
 * className 类名
 * content_img_url 图片路径
 * dataSource_mb 移动端文案数据
 */
interface TimeLineProps {
  className?: string
  content_img_url?: string | { publicURL: string }
  dataSource_mb: { title: string; desc: string }[]
}

const TimeLine: React.FC<TimeLineProps> = ({ className, content_img_url, dataSource_mb }) => {
  const isMb = useIsWindowWidthSmaller()
  return (
    <div className={classnames(styles.container, 'lg:w-[1200px] mx-auto  px-[2rem] lg:px-0', className)}>
      {!isMb && (
        <>
          <span className="section3--img-bg" />
          <img
            className="lg:inline-block hidden"
            src={typeof content_img_url === 'string' ? content_img_url : content_img_url?.publicURL}
            alt=""
          />
        </>
      )}
      {isMb && (
        <>
          {dataSource_mb?.map((item) => {
            return (
              <div
                className="mr-0 ml-auto w-[310px] h-[96px] mb-[14px] pl-[30px] pt-[14px] section3-card"
                key={item?.title}
              >
                <p className="text-[16px] text-[#1f2d3d] leading-[22px font-medium]">{item?.title}</p>
                <p className="text-[14px] text-[#475669] leading-[20px] whitespace-pre-wrap">{item?.desc}</p>
                <span className="top-dotted-line" />
                <span className="bottom-dotted-line" />
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

export default TimeLine
