import React, { useRef } from 'react'
import { graphql, PageProps } from 'gatsby'
import Seo from 'components/seo'
import Section from 'components/SolutionComponents/Section'
import Introduce from 'components/Product/Introduce'
import AnalysisCard from 'components/Analysis/AnalysisCard'
import GreenCard from 'components/Analysis/GreenCard'
import TestimonyCard from 'components/Solutions/TestimonyCard'
import VideoMask from 'components/VideoMask'
import { Banner, MoreResources, PageBottomCard, VideoPlayer, TabSwitcher } from 'components/index'
import Button, { ButtonGroup } from 'components/Base/Button'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import TimeLine from 'components/Analysis/TimeLine'
import Layout from 'layouts/zh'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import * as styles from './index.module.less'

interface Props {
  data: any
}

const Analysis: React.FC<PageProps<Props['data']>> = (props) => {
  const { data } = props
  const { productYaml } = data
  return (
    <Layout {...props}>
      <Seo
        title={productYaml?.title}
        description={productYaml?.desc}
        keywords={productYaml?.keywords}
        saTitle={productYaml?.saTitle}
      />
      <AnalysisState data={productYaml} />
    </Layout>
  )
}

export const AnalysisState: React.FC<Props['data']> = (props) => {
  const curVideo = useRef<any>(null)
  const { data } = props
  const { banner, section1, section2, section3, section4, section5, section6, end } = data
  const isMb = useIsWindowWidthSmaller()
  return (
    <main className={classnames(styles.container, 'bg-[#F9FAFC] lg:bg-[#fff]')}>
      {/* banner 神策分析*/}
      <div className={styles.bannerWrapper}>
        <Banner
          className={classnames(styles.banner)}
          curVideo={curVideo}
          duration="03:16"
          h1tag={true}
          {...banner}
          videoBg={banner?.video_bg}
          bannerTitleClassName={styles.bannerTitle}
          bannerDescClassName={styles.bannerDesc}
          bannerSubTitleClassName={styles.bannerSubTitleClassName}
          videoBtnWraClassName={styles.videoBtnWra}
          bannerBtnsClass={styles.bannerBtnsClass}
        />
      </div>
      {isMb && (
        <div className="px-[2rem] bg-[#f9fafc] w-full h-full">
          <VideoMask className="rounded-[4px] overflow-hidden" onClick={() => curVideo?.current?.playVideo()}>
            <img className="w-full h-full" src={banner?.video_bg?.publicURL ?? banner?.video_bg} />
          </VideoMask>
        </div>
      )}
      {/* section1 深耕业务全场景，数据驱动科学决策 */}
      <Section className={classnames(styles.section1)} title={section1?.title}>
        <div className="lg:w-[1200px] mt-[16px] mx-auto lg:mt-[60px] lg:mb-[80px]">
          <TabSwitcher
            data={section1?.list}
            longTitle={section1?.longTitle}
            align="space-between"
            mbShowShadow={false}
            // fixedWandH
            autoWAndH
          />
        </div>
      </Section>
      {/* section2 四大全新能力，赋能用户经营场景 */}
      <Section title={section2?.title} className={classnames('!pt-[40px] lg:!mt-0 lg:!pt-[70px] bg-[#F9FAFC]')}>
        <div className="lg:w-[1200px] lg:mt-[60px] mt-[20px] mx-auto px-[2rem] lg:px-0 lg:flex lg:justify-between">
          {section2?.dataSource?.map((item) => {
            return <AnalysisCard className="mt-[20px] lg:mt-0" {...item} key={item?.title} />
          })}
        </div>
        <ButtonGroup className="text-center pt-[30px] lg:pb-[70px]">
          <ScanCodeConsultButton
            qrcode={section2?.left_btn_href}
            buttonText={section2?.left_btn_text}
            imgBottomText="微信扫码 立即咨询"
            btnType="primary"
          />
          <Button className="ml-[20px]" href={section2?.right_btn_href} target="_blank" btnType="primary" ghost>
            {section2?.right_btn_text}
          </Button>
        </ButtonGroup>
      </Section>
      {/* section3 产品历经八年打磨，不断追求卓越 */}
      <Section className="lg:pb-[100px]" title={section3?.title}>
        <TimeLine className="mt-[26px] lg:mt-[60px] " {...section3} />
        <div className="lg:relative mt-[30px] lg:mt-[80px] text-center">
          <Button target="_blank" btnType="primary" href={section3?.btn_href}>
            {section3?.btn_text}
          </Button>
        </div>
      </Section>
      {/* section4  为什么选择神策？*/}
      <Section className="bg-[#f9fafc] lg:!mt-0 lg:pb-[60px] lg:pt-[60px]" title={section4?.title}>
        <div className="lg:mt-[60px] mt-[26px] lg:w-[1200px] mx-auto px-[2rem] lg:px-0 lg:flex lg:justify-between">
          {section4?.dataSource?.map((item) => {
            return <GreenCard className="mt-[14px] lg:mt-0" key={item?.title} dataSource={item} />
          })}
        </div>
        <ButtonGroup className="lg:mt-[60px] mt-[30px] text-center">
          <Button href={section4?.left_btn_href} target="_blank" btnType="primary">
            {section4?.left_btn_text}
          </Button>
          <ScanCodeConsultButton
            className="ml-[20px]"
            qrcode={section4.right_btn_href}
            buttonText={section4?.right_btn_text}
            imgBottomText="微信扫码 立即咨询"
            btnType="primary"
            ghost
          />
        </ButtonGroup>
      </Section>
      {/* section5 客户证言 */}
      <Section className="px-[2rem] lg:px-0" title={section5?.section_title}>
        <div className="lg:w-[1070px]  mx-auto lg:mt-[50px] mt-[20px] bg-[#fff]  mb-[10px] lg:mb-0">
          <TestimonyCard {...section5} />
        </div>
      </Section>
      {/* section6 更多干货 */}
      <Section className="lg:pb-[60px] lg:pt-[70px] bg-[#F9FAFC]" title={section6?.title}>
        <div className="lg:mt-[60px] mt-[26px] px-[2rem] lg:px-0 ">
          <MoreResources data={section6?.dataSource} />
        </div>
      </Section>
      {/* end 开启大数据分析与营销科技之旅！*/}
      <div className="lg:mt-[100px] mt-[60px]">
        <PageBottomCard {...end} />
      </div>
      {/* VideoPlayer */}
      <VideoPlayer
        videoOpt={{
          sources:
            'https://video.sensorsdata.cn/d67eca15f8c54b8a9b8f43a4de6dfc5f/61a2b96459b942d9a036b8f6b30d918b-13e538f85a30b9062e95e0c90b00dda4-sd.mp4',
        }}
        fuRef={curVideo}
      />
    </main>
  )
}

export const query = graphql`
  query {
    productYaml(page_name: { eq: "神策分析" }) {
      title
      desc
      keywords
      saTitle
      banner {
        title
        subTitle
        desc
        banner {
          publicURL
        }
        video_bg {
          publicURL
        }
        buttons {
          text
          href
          btnType
          ghost
        }
      }
      section1 {
        title
        longTitle
        list {
          title
          reverse
          showIcon
          sortTitle
          content {
            img {
              publicURL
            }
            alt
            introduction1
            buttons {
              btnType
              href
              text
            }
            introductionList {
              content
            }
          }
        }
      }
      section2 {
        title
        dataSource {
          title
          desc
          img_url {
            publicURL
          }
        }
        left_btn_text
        left_btn_href
        right_btn_text
        right_btn_href
      }
      section3 {
        title
        content_img_url {
          publicURL
        }
        btn_text
        btn_href
        dataSource_mb {
          title
          desc
        }
      }
      section4 {
        title
        dataSource {
          title
          desc
          content
        }
        left_btn_text
        left_btn_href
        right_btn_text
        right_btn_href
      }
      section5 {
        section_title
        imgUrl {
          publicURL
        }
        imgAlt
        title
        text
        signature
      }
      section6 {
        title
        dataSource {
          title
          tip
          url
          img {
            publicURL
          }
          img_mb {
            publicURL
          }
          alt
          text
        }
      }
      end {
        title
        desc
        leftButtonText
        leftButtonHref
        rightButtonText
        rightButtonHref
      }
    }
  }
`

export default Analysis
